import React, { useEffect, useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { FaSignOutAlt, FaShoppingCart, FaExchangeAlt, FaClipboardList, FaBars, FaDollarSign, FaCreditCard, FaMoneyBillWave, FaStore, FaWarehouse } from "react-icons/fa"; // FaBars ավելացված
import { MdInventory } from "react-icons/md";
import "./style.scss";
import { Axios } from "../../Axios";
import { User } from "../../Types/Types";
import { CassaSum } from "../../pages/CassaSum";

export const Menu: React.FC = React.memo((): JSX.Element => {
  const [bool, setBool] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User>({} as User);
  const navigate = useNavigate();
  const location = useLocation(); // Ստանալ ընթացիկ URL-ը

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const IsLogin = await Axios.get("auth/check-auth");
        if(IsLogin.data.valid == true)
        {
          await GiveUser(IsLogin.data.username);
        }
      } catch (error) {
        console.error("Error fetching login status:", error);
      }
    };
    checkLogin();
    if(location.pathname == "/")
    {
        setBool(false);
    }
    else
    {
      setBool(true);
    }
    setMenuOpen(false);
    console.log(user);
  }, [location]);

  const signOut = async () => {
    const IsLogin = await Axios.post("auth/logout");
    console.log(IsLogin.data.logout == true)
    {
      navigate("/")
      setBool(false)
    }
  };

  const handleMenuClick = () => {
    setMenuOpen(false);
  };
const GiveUser = async (username:string) => {
        const res:any = await Axios.get(`program/user?Username=${username}`);
        if(res.data?.user != null){
            setUser({...res.data?.user});
        }
    }
  return (
    <div>
      {bool ? (
        <div className="menu">
          <nav className="menu_nav">
            <button className={`hamburger ${menuOpen ? "active" : ""}`} onClick={() => setMenuOpen(!menuOpen)}>
              <FaBars /> {/* ☰ փոխարինվեց FaBars icon-ով */}
            </button>

            <ul className={`menu-list ${menuOpen ? "open" : ""}`}>
              <>
              {user.RoleID == 1
              ?
              <>
                <li><Link to="/sale" onClick={handleMenuClick}><FaShoppingCart /> Վաճառք</Link></li>
                <li><Link to="/payment" onClick={handleMenuClick}><FaCreditCard /> Վճարումներ</Link></li>
                <li><Link to="/Income" onClick={handleMenuClick}><FaDollarSign  /> Եկամուտներ</Link></li>
                <li><Link to="/purchase" onClick={handleMenuClick}><FaClipboardList /> Առք</Link></li>
                <li><Link to="/movement" onClick={handleMenuClick}><FaExchangeAlt /> Տեղաշարժ</Link></li>
                <li><Link to="/MyWarehouse" onClick={handleMenuClick}><FaWarehouse  /> Պահեստի ապրանքներ</Link></li>
                <li><Link to="/Product_directory" onClick={handleMenuClick}><MdInventory  /> Ապրանքի տեղեկատու</Link></li>
                <li><Link to="/CassaSum" onClick={handleMenuClick}><MdInventory /> Դրմարկղ</Link></li>
                <li><button onClick={signOut}><FaSignOutAlt /> Ելք</button></li>
              </>
              :
              <>
                <li><Link to="/sale" onClick={handleMenuClick}><FaShoppingCart /> Վաճառք</Link></li>
                <li><Link to="/payment" onClick={handleMenuClick}><FaCreditCard /> Վճարումներ</Link></li>
                <li><Link to="/Income" onClick={handleMenuClick}><FaDollarSign  /> Եկամուտներ</Link></li>
                <li><Link to="/reception" onClick={handleMenuClick}><FaStore  /> Ընդունում</Link></li>
                <li><Link to="/MyWarehouse" onClick={handleMenuClick}><FaWarehouse /> Խանութի ապրանքներ</Link></li>
                <li><Link to="/Product_directory" onClick={handleMenuClick}><MdInventory /> Ապրանքի տեղեկատու</Link></li>
                <li><Link to="/CassaSum" onClick={handleMenuClick}><MdInventory /> Դրմարկղ</Link></li>
                <li><Link to="/SalesHistory" onClick={handleMenuClick}><MdInventory /> Վաճառքների պատմություն</Link></li>
                <li><button onClick={signOut}><FaSignOutAlt /> Ելք</button></li>
              </>
              }
              </>
            </ul>
          </nav>
        </div>
      ) : (
        <>
        </>
      )}
    </div>
  );
});
