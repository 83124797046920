import React, { Children } from "react";
import { useRoutes } from "react-router-dom";
import './style.scss'
import { Error } from "../../pages/Error";
import { Layout } from "../../pages/Layout";
import { Login } from "../../pages/Login";
import { Profile } from "../../pages/Profile";
import { Sale } from "../../pages/Sale";

import path from "path";
import { Purchase } from "../../pages/Purchase";
import { Movement } from "../../pages/Movement";
import { Product_directory } from "../../pages/Product_directory";
import { Income } from "../../pages/Income";
import { Payment } from "../../pages/Payment";
import { Reception } from "../../pages/Reception";
import { MyWarehouse } from "../../pages/MyWarehouse";
import { CassaSum } from "../../pages/CassaSum";
import { SalesHistory } from "../../pages/SalesHistory";

export const MyRouter:React.FC=React.memo(()=>{
    const router=useRoutes([
        {
            path:'/',
            element:<Layout/>,
            children:[
                {
                    path:'',
                    element:<Login/>
                },
                {
                    path:'profile',
                    element:<Profile/>
                },
                {
                    path:'sale',
                    element:<Sale/>
                },
                {
                    path:'purchase',
                    element:<Purchase/>
                },
                {
                    path:'movement',
                    element:<Movement/>
                },
                {
                    path:'Income',
                    element:<Income/>
                },
                {
                    path:'Product_directory',
                    element:<Product_directory/>
                },
                {
                    path:'payment',
                    element:<Payment/>
                },
                {
                    path:'reception',
                    element:<Reception/>
                },
                {
                    path:'MyWarehouse',
                    element:<MyWarehouse />
                },
                {
                    path:'CassaSum',
                    element:<CassaSum />
                }
                ,
                {
                    path:'SalesHistory',
                    element:<SalesHistory />
                }
            ]
        },{
            path:'*',
            element:<Error/>
        }
    ])
    return router
})