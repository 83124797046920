import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { CreatUserType } from "../../Types/Types";
import "./style.scss"; // եթե չկա, ապա ավելացրեք
import { Axios } from "../../Axios";

export const Login: React.FC = React.memo((): JSX.Element => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreatUserType>();

  const save = async (data: CreatUserType) => {
    try {
      const response = await Axios.post("auth/login", data );
      // console.log(response.data);
      if(response.data.Login){
        navigate("/sale");
      }
      else
      {
        Swal.fire({
          icon: "error",
          title: "",
          text: "Օգտանունը կամ գաղտնաբառը սխալ է!",
          footer: '',
          confirmButtonText : "Լավ"
        });      
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Օգտանունը կամ գաղտնաբառը սխալ է!",
        footer: '',
        confirmButtonText : "Լավ"
      }); 
    }
  };
  useEffect(() => {
    const checkLogin = async () => {
      try {
        const IsLogin = await Axios.get("auth/check-auth");
        console.log(IsLogin);
        if(IsLogin.data.valid == true)
        {
          navigate("/sale");
        }
      } catch (error) {
        console.error("Error fetching login status:", error);
      }
    };
  
    checkLogin();
  }, []);
  return (
    <div className="login-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-center mb-4">Մուտք համակարգ</h5>
                <form onSubmit={handleSubmit(save)}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">Օգտանուն</label>
                    <input
                      {...register("username", { required: "Օգտանուն դաշտը պարտադիր է!" })}
                      type="text"
                      className="form-control"
                      id="username"
                    />
                    {errors.username && <p className="text-danger">{errors.username.message}</p>}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Գաղտնաբառ</label>
                    <input
                      {...register("password", { required: "Գաղտնաբառ դաշտը պարտադիր է!" })}
                      type="password"
                      className="form-control"
                      id="password"
                    />
                    {errors.password && <p className="text-danger">{errors.password.message}</p>}
                  </div>

                  <button type="submit" className="btn btn-primary w-100">
                    Մուտք
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
