import React, { useEffect, useState } from "react";
import { IDName, SearchBuyerForm } from "../../Types/SaleType";
import { MovmentDoc, MovmentDocProduct } from "../../Types/MovmentType";
import { Axios } from "../../Axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // ✅ Bootstrap ներմուծում ենք
import "./style.scss"
import { User } from "../../Types/Types";
import { PaymentBuyer, PaymentsDoc, PersonsDebtDetails } from "../../Types/PaymentType";
import { Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { FaChevronDown, FaChevronUp, FaMoneyBillWave, FaSearch } from "react-icons/fa";
import Swal from "sweetalert2";
import { Helpers } from "../../Helpers";
import { stat } from "fs";


export const Payment: React.FC = React.memo((): JSX.Element => {
  const navigate = useNavigate();

  const [key, setKey] = useState<string>("debts");
  // ✅ Պահում ենք state-ը մեկ օբյեկտի մեջ
  const [user, setUser] = useState<User>({} as User);
  const [expandedDoc, setExpandedDoc] = useState<number | null>(null);
  const [docState, setDocState] = useState<PaymentsDoc>({
    DocGiveSumAMD: 0,
        CreateUserID: 0,
        Notes: "",
        FromID:0,
        ToID:0,
        CassaID:0,
        personsDebts:[],
        paymentDebts:[]
  } as PaymentsDoc);
  const [state, setState] = useState({
    buyers: [] as PaymentBuyer[], // For buyers data
    searchBuyer: { Name: "", Phone: "" } as SearchBuyerForm, // For search buyer form
  });

  // 📌 Ընդհանուր state թարմացնելու ֆունկցիա
  const updateState = (newValues: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newValues }));
  };

  const GiveUser = async (username:string) => {
      const res:any = await Axios.get(`program/user?Username=${username}`);
      if(res.data?.user != null){
          setUser({...res.data?.user});
          setDocState({...docState,ToID:res.data?.user.ShopStoreID,CassaID:res.data?.user.CassaID,CreateUserID :res.data?.user.ID });
      }
  }


  // 📌 Ապահովում ենք որ մուտք գործած լինի
  useEffect(() => {
    const checkLogin = async () => {
      try {
        const IsLogin = await Axios.get("auth/check-auth");
        if (IsLogin.data.valid === false) {
          navigate("/"); // Չես մուտք գործել, ուղարկում ենք շուտով
        } else {
          await GiveUser(IsLogin.data.username);
        }
      } catch (error) {
        navigate("/"); // Հաշիվ չկա, ուղարկում ենք տնային էջ
      }
    };

    checkLogin();
  }, []);

  
const handleSearchBuyer = async () => {
    try {
      // if (state.searchBuyer.Name.trim() === "" && state.searchBuyer.Phone.trim() === "") {
      //   setState((prevState) => ({ ...prevState, buyers: [] }));
      //   return;
      // }
       setDocState({...docState,FromID:null})
      const res = await Axios.get(`program/paymentpersons?Name=${state.searchBuyer.Name.trim()}&Phone=${state.searchBuyer.Phone.trim()}`);
      // console.log(res.data.length)
      setState((prevState) => ({
        ...prevState,
        buyers: res.data
      }));
      if(res.data.length == 1){
        setDocState({...docState,ToID:res.data[0].ID});
        //selectCustomer(res.data[0].ID);
      }

      
    } catch (error) {
      Swal.fire("Սխալ", "Տվյալների որոնման ընթացքում եղավ սխալ", "error");
    }
  };

    const handleKeyPress = (e: React.KeyboardEvent, searchType: "buyer" | "product") => {
      if (e.key === "Enter") {
        if (searchType === "buyer") {
          handleSearchBuyer();
        } else {
          // handleSearchProduct();
        }
      }
    };

    const selectCustomer = async (customerID:number) => {
      console.log(customerID);
      setState({...state,buyers:state.buyers.filter(e=>e.ID == customerID)})

      // setDocState({...docState,ToID:customerID})
      const res = await Axios.get(`program/getPersonDetails?PersonsID=${customerID}`);
      if(res.data){
        setDocState({...docState,FromID:customerID,personsDebts:res.data.personDebt,paymentDebts:res.data.paymentDebts,DocGiveSumAMD:0 });
      }
    }
    const handlePayment = async () => {
      if (!docState.DocGiveSumAMD || docState.DocGiveSumAMD <= 0) {
        Swal.fire("Սխալ", "Խնդրում ենք մուտքագրել ճիշտ վճարման գումար:", "warning");
        return;
      }

      const result = await Swal.fire({
        title: "Վճարում կատարե՞լ",
        text: "Համոզվա՞ծ եք, որ ցանկանում եք վճարում կատարել",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Այո",
        cancelButtonText: "Ոչ",
      });
    
      if (!result.isConfirmed) {
        return; // Եթե օգտատերը չհաստատի, դուրս ենք գալիս ֆունկցիայից
      }
    
      const payload = {
        DocGiveSumAMD: docState.DocGiveSumAMD,
        CreateUserID: user.ID, // Օգտատիրոջ ID
        Notes: docState.Notes || "",
        FromID: docState.FromID,
        ToID: user.ShopStoreID,
        CassaID: user.CassaID,
      };
    
      try {
        const { data } = await Axios.post("program/createPayDoc", payload);
        console.log(data);
        Swal.fire({
                  position:"center",
                  icon: "success",
                  title: "Վճարումը հաջողությամբ կատարվեց",
                  showConfirmButton: false,
                  timer: 1500
              });  
    
        // Թարմացնել state-ը վճարումից հետո
        setDocState(prev => ({
          ...prev,
          DocGiveSumAMD: 0, // Վճարման գումարը զրոյացնել
        }));
        await selectCustomer(docState.FromID ?? 0);
        state.buyers[0].DebtSum = data.remainingDebt == null ? state.buyers[0].DebtSum : data.remainingDebt;
        setState({...state})
      } catch (error : any) {
        console.error("Վճարման սխալ:", error);
        Swal.fire("Սխալ", error.response?.data?.error || "Վճարման ընթացքում սխալ առաջացավ", "error");
      }
    };
    
      const toggleDocDetails = async (debtID: number) => {
        if (expandedDoc === debtID) {
          setExpandedDoc(null);
        } else {
          let index = docState.personsDebts.findIndex(e=>e.DebtID == debtID);
          if(index != -1)
          {
            const response = await Axios.get(`/program/debtproducts?DebtID=${debtID}`);
            console.log(response);
            if(response.data){
              docState.personsDebts[index].Products = response.data;
              setExpandedDoc(debtID);
            }
          }
        }
      };
        const openPhone = (data: PersonsDebtDetails) => {
          Swal.fire({
            title: "",
            text: "",
            imageUrl: data.ProductImg,
            imageWidth: 400,
            imageHeight: 400,
            imageAlt: "Նկարը բացակայում է",
            confirmButtonText: "Փակել",
          });
        };
  return (
    <div className="Payment">

         <div className="buyer-search-form" style={{marginBottom: "10px"}}>
                <Row className="align-items-center">
                  <Col md={6} xs={6}>
                    <Form.Group controlId="formName">
                      <Form.Control
                        type="text"
                        placeholder="Անուն"
                        onChange={(e) => setState({ ...state, searchBuyer: { ...state.searchBuyer, Name: e.target.value } })}
                        onKeyDown={(e) => handleKeyPress(e, "buyer")} // Enter key press for buyer search
                      />
                    </Form.Group>
                  </Col>
                  <Col md={5} xs={5}>
                    <Form.Group controlId="formPhone">
                      <Form.Control
                        type="text"
                        placeholder="Հեռախոս"
                        onChange={(e) => setState({ ...state, searchBuyer: { ...state.searchBuyer, Phone: e.target.value } })}
                        onKeyDown={(e) => handleKeyPress(e, "buyer")} // Enter key press for buyer search
                      />
                    </Form.Group>
                  </Col>
                  <Col md={1} xs={1} className="d-flex justify-content-end">
                    <button onClick={handleSearchBuyer} className="btn_col">
                      <FaSearch />
                    </button>
                  </Col>
                </Row>
              </div>
      
              {state.buyers.length > 0 && (
                <div className="buyer-details mt-2" style={{overflow:"auto",maxHeight:"450px"}}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Անուն</th>
                        <th>Հեռախոս</th>
                        <th>Պարտք</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.buyers.map((buyer) => (
                        <tr key={buyer.ID} onClick={() => selectCustomer(buyer.ID)}
                          className={buyer.ID == docState.FromID ? "color_buyer" : ""} 
                         >
                          
                          <td>{buyer.Name}</td>
                          <td>{buyer.Phone}</td>
                          <td>{Helpers.formatNumberText(buyer.DebtSum)}</td>
                          
                        </tr>
                      ))}
                    </tbody>
                    {state.buyers.length > 1
                    ?
                    <tfoot>
                      <tr>
                        <th colSpan={2}>Ընդամենը</th>
                        <th>{Helpers.formatNumberText(state.buyers.reduce((a,b)=>a + +b.DebtSum,0))}</th>
                      </tr>
                    </tfoot>
                    :
                    <></>
                    }
                  </Table>
                </div>
              )}
              {docState?.FromID != null && docState?.FromID != 0
              ?
              <>
              <Tabs activeKey={key} onSelect={(k) => setKey(k || "debts")} className="mb-3 tab_db" style={{gap:"20px",justifyContent:"center"}} >
                <Tab eventKey="debts" title="Պարտքեր">
                  <div style={{overflow:"auto",height:"350px"}}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Պարտք</th>
                          <th>Վճարված</th>
                          <th>Մնացորդ</th>
                          <th>Ամսաթիվ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {docState.personsDebts.filter(e=>e.DebtSum > 0).map((data) => (
                          <React.Fragment key={data.DebtID}>

                            <tr key={data.DebtID}
                            // className={buyer.ID == docState.ToID ? "color_buyer" : ""} 
                            >
                              <td>
                                <button
                                  className="btn btn-sm btn-outline-primary"
                                  onClick={() => toggleDocDetails(data.DebtID)}
                                  >
                                  {expandedDoc === data.DebtID ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                              </td>
                              <td>{Helpers.formatNumberText(data.AllDebtSum)}</td>
                              <td>{Helpers.formatNumberText(data.PaySum)}</td>
                              <td><b style={{color:"red"}}> {Helpers.formatNumberText(data.DebtSum)}</b></td>
                              <td>{data.Dtn}</td>
                            </tr>
                            {expandedDoc === data.DebtID && (
                                <tr>
                                  <td colSpan={5}>
                                    <div className="">
                                      {/* <h5 className="mb-3">Հղված Ապրանքներ</h5> */}
                                      <div className="table-responsive">
                                        <table className="table table-bordered table-hover text-center">
                                          <thead className="table-secondary">
                                            <tr>
                                              <th>Կոդ</th>
                                              <th>Նկար</th>
                                              {/* <th>Անուն</th> */}
                                              <th>Քան</th>
                                              <th>Գին</th>
                                              <th>Ընդամենը</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {data.Products?.map((prod:PersonsDebtDetails ) => (
                                              <tr key={prod.ProductID}>
                                                <td>{prod.ProductID}</td>
                                                <td>
                                                  <img
                                                    src={prod.ProductImg}
                                                    width={50}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => openPhone(prod)}
                                                  />
                                                </td>
                                                {/* <td>{prod.ProductName}</td> */}
                                                <td>{Helpers.formatNumberText(prod.ProductCount)}</td>
                                                <td>{Helpers.formatNumberText(prod.ProductPriceDesc)}</td>
                                                <td>{Helpers.formatNumberText(prod.ProductPriceDescSum)}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Tab>

              <Tab eventKey="payments" title="Վճարումներ">
                <div style={{overflow:"auto",height:"350px"}}>
                  <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Վճ գումար</th>
                          <th>Ամսաթիվ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {docState.paymentDebts.map((data) => (
                          <tr key={data.ID}
                          // className={buyer.ID == docState.ToID ? "color_buyer" : ""} 
                          >
                            <td>{Helpers.formatNumberText(data.DocGiveSum)}</td>
                            <td>{data.Dtn}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="olddebts" title="Հին Պարտքեր">
                  <div style={{overflow:"auto",height:"350px"}}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Պարտք</th>
                          <th>Վճարված</th>
                          <th>Մնացորդ</th>
                          <th>Ամսաթիվ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {docState.personsDebts.filter(e=>e.DebtSum == 0).map((data) => (
                          <tr key={data.DebtID}
                          // className={buyer.ID == docState.ToID ? "color_buyer" : ""} 
                          >
                            <td>{Helpers.formatNumberText(data.AllDebtSum)}</td>
                            <td>{Helpers.formatNumberText(data.PaySum)}</td>
                            <td><b> {Helpers.formatNumberText(data.DebtSum)}</b></td>
                            <td>{data.Dtn}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
              </Tabs>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center',  // Թեքստն ու ինփուտ դաշտը իրար կողքի
                fontWeight: 'bold', 
                fontSize: '18px',
                gap:"10px"
              }}>
                <label style={{ marginRight: '10px' }}>Վճ․ Գումար &nbsp;:</label>
                  <Form.Control
                    type="number"
                    value={docState.DocGiveSumAMD == 0 ? "" : docState.DocGiveSumAMD} 
                    // onBlur={(e) => changeDocGiveSumAMD(Number(e.target.value))}
                    onChange = {(e) => {
                      setDocState(prevDocState => ({
                        ...prevDocState,
                        DocGiveSumAMD: Number(e.target.value)
                      }));
                    }}
                    style={{
                      width: '100%',
                      maxWidth: '120px',
                    }}
                    />
                    <button onClick={handlePayment} className="btn bg-primary text-white">
                        Վճարել
                      </button>

                </div>
                </>
            :
            <></>
            }
            
    </div>
  );
});
