import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import { IncomeType, User } from "../../Types/Types";
import { Axios } from "../../Axios";
import { Helpers } from "../../Helpers";


export const Income: React.FC = React.memo((): JSX.Element => {
      const [user, setUser] = useState<User>({} as User);

    const [state,setState] = useState({
      incomes:[] as IncomeType[],
      todayIncome:0 as number,
      allIncome:0 as number
    });
    const navigate = useNavigate()
    useEffect(() => {
        const checkLogin = async () => {
              try {
                const IsLogin = await Axios.get("auth/check-auth");
                // console.log(IsLogin.data.valid);
                if(IsLogin.data.valid == false)
                {
                  navigate("/");
                }
                else
                {
                  await GiveUser(IsLogin.data.username);
                  await handleSearchIncome();
                }
              } catch (error) {
                navigate("/");
              }
            };
          
            checkLogin();
    }, [])

    const GiveUser = async (username:string) => {
        const res:any = await Axios.get(`program/user?Username=${username}`);
        if(res.data?.user != null){
          user.ID = res.data?.user.ID;
          user.RoleID = res.data?.user.RoleID;
          user.ShopStoreID = res.data?.user.ShopStoreID;
            setUser({...res.data?.user});
        }
    }
    const handleSearchIncome = async () => {
      try {
        let queryParams = `FromID=${encodeURIComponent(user.ShopStoreID)}`;
    
        let res:any;
        if(user.RoleID == 1)
        {
          res = await Axios.get(`program/saleincome2`);
        }
        else
        {
          res = await Axios.get(`program/saleincome?${queryParams}`);
        }
    
        console.log(res);
    
        setState((prevState) => ({
          ...prevState,
          incomes: res.data.incomes, // Այսօր ստացված տվյալները
          todayIncome: res.data.todayIncome || 0, // Այսօր ստացված եկամուտը
          allIncome: res.data.allIncome || 0, // Ընդհանուր եկամուտը
        }));
      } catch (error) {
        console.error("Error fetching income data:", error);
        Swal.fire("Սխալ", "Եկամուտների որոնման ընթացքում սխալ առաջացավ", "error");
      }
    };
    
        const openPhone = (data : IncomeType) => {
            Swal.fire({
                title: "",
                text: "",
                imageUrl: data.ProductUrl,
                imageWidth: 400,
                imageHeight: 400,
                imageAlt: "Նկարը բացակայում է",
                confirmButtonText : "Փակել"
              });
        }
    return <div className="Income">
            <div className="income_sum">
                <span style={state.todayIncome < 0 ? {color:"red"} : {color:"green"}}>
                   {Helpers.formatNumberText(state.todayIncome)}
                   </span>

                <span style={state.allIncome < 0 ? {color:"red"} : {color:"green"}}>
                   {Helpers.formatNumberText(state.allIncome)}
                </span>

            </div>
            <hr style={{margin:"10px"}}/>
            <div style={{overflow:"auto",maxHeight:"420px"}}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Նկար</th>
                  <th>Ապրանք</th>
                  <th>Քանակ</th>
                  <th>Գումար</th>

                </tr>
              </thead>
              <tbody>
                {state.incomes.map((data) => (
                  <tr key={data.ProductName + data.ProductCount}>
                    <td><img src={data.ProductUrl} width={50} onClick={() => openPhone(data)} /></td>
                    <td><span style={{flexWrap:"wrap"}}>{data.ProductName}</span></td>
                    <td style={{fontWeight:"700",color:"blue"}}>{data.ProductCount}</td>
                    <td>
                        <div style={{fontWeight:"700"}}>
                            <span style={data.ProductIncome >= 0 ? {color:"green"} : {color:"red"}}>{Helpers.formatNumberText(data.ProductIncome)}</span>
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </div>
    </div>
})