import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { AddProductModal } from "../../component/Components/AddProductModal";
import { ProductCU } from "../../Types/ProductType";
import { IDName } from "../../Types/SaleType";
import { FaEdit, FaFilter, FaPlus } from "react-icons/fa";
import { Axios } from "../../Axios";
import { Table } from "react-bootstrap";
import { UpdateProductModal } from "../../component/Components/UpdateProductModal";


export const Product_directory: React.FC = React.memo((): JSX.Element => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        showCreateModal: false,
        showUpdateModal: false,
        selectedProduct: null as ProductCU | null, // Added selected product
        productTypes: [] as IDName[],
        products: [] as ProductCU[],
        FProductType:0,
        FProductName:""
    });
    
    useEffect(() => {
        const checkLogin = async () => {
              try {
                const IsLogin = await Axios.get("auth/check-auth");
                if(IsLogin.data.valid == false)
                {
                  navigate("/");
                }
                else
                {
                    await GiveProductTypes();
                    // await GiveProduct();
                }
              } catch (error) {
                navigate("/");
              }
            };
          
            checkLogin();
    }, [])
    const GiveProductTypes = async () => {
        const res = await Axios.get("program/productType");
        if (res?.data?.length > 0) {
            setState(prevState => ({
                ...prevState,
                productTypes: res?.data
            }));
        }
        // console.log(res?.data);
    }
    
    const GiveProduct = async () => {
        const res = await Axios.get(`product?Name=${state.FProductName}&ProductType=${state.FProductType}`);
        if (res?.data?.length > 0) {
            setState(prevState => ({
                ...prevState,
                products: res?.data
            }));
        }
    }
    
    const openPhone = (data : ProductCU) => {
        Swal.fire({
            title: "",
            text: "",
            imageUrl: data.ImageURL,
            imageWidth: 400,
            imageHeight: 400,
            imageAlt: "Նկարը բացակայում է",
            confirmButtonText : "Փակել"
          });
    }

    const saveProductModal = async (data : ProductCU) => {
       try
        {
        // const res:any = {data:{actionTaken:true,ID:1}};
        const res:any = await Axios.post(`product`,data);
            console.log(res)
            if(res?.data?.actionTaken === true)
            {
                setState({
                    ...state, 
                    products:[
                    {
                        ID : res?.data?.ID,
                        Name : data.Name,
                        SealPriceAMD : data.SealPriceAMD,
                        DiscountedPriceAMD : data.DiscountedPriceAMD,
                        ProductTypeName : state.productTypes?.find(e => e.ID == data.ProductType)?.Name || "Առանց տեսակի",
                        ProductType : data.ProductType,
                        Description : data.Description,
                        ImageURL : data.ImageURL,


                    }
                    ,...state.products],
                    showCreateModal:false
                });
            }
        }
        catch(err)
        {
            Swal.fire({
            icon:"error",
            text: "Ապրանք ավելացնել չհաջողվեց!",
            confirmButtonText : "Լավ"
            });   
        }
    }

    const openProductModal = () => {
        setState({...state,showCreateModal:true})
    }
    const closeProductModal = () => {
        setState({...state,showCreateModal:false})
    }

    // Update Products
    const updateProductModal = async (data : ProductCU) => {
        try
         {
        //  const res:any = {data:{actionTaken:true}};
         const res:any = await Axios.put(`product/${data.ID}`,data);

 
             console.log(res)
             if(res?.data?.actionTaken === true)
             {
                 setState({
                     ...state,
                     products:[
                     {
                         ID : data.ID,
                         Name : data.Name,
                         SealPriceAMD : data.SealPriceAMD,
                         DiscountedPriceAMD : data.DiscountedPriceAMD,
                         ProductTypeName : state.productTypes?.find(e => e.ID == data.ProductType)?.Name || "Առանց տեսակի",
                         ProductType : data.ProductType,
                         Description : data.Description,
                         ImageURL : data.ImageURL,
                     },...state.products.filter(e=>e.ID != data.ID)],
                     showUpdateModal:false
                 });
             }
             console.log(state)
         }
         catch(err)
         {
             Swal.fire({
             icon:"error",
             text: "Ապրանք թարմացնել չհաջողվեց!",
             confirmButtonText : "Լավ"
             });   
         }
     }
    const openProductUpModal = (product: ProductCU) => {
        setState({ ...state, showUpdateModal: true, selectedProduct: product });
    };
    
    const closeUpdateUpModal = () => {
        setState({ ...state, showUpdateModal: false, selectedProduct: null });
    };
     /// 
        const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setState((prevState) => ({
                ...prevState,
                FProductName: event.target.value
            }));
        };
        
        const handleProductTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
            setState((prevState) => ({
                ...prevState,
                FProductType: Number(event.target.value) // Ensure it's a number
            }));
        };
        const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                GiveProduct(); // Call the function when Enter is pressed
            }
        };
    return <div className="Product_directory">
        <AddProductModal 
        showModal = {state.showCreateModal} 
        hideModal = {closeProductModal}
        saveModal = {saveProductModal} 
        productTypes = {state.productTypes}/>
        <UpdateProductModal
            showModal={state.showUpdateModal}
            hideModal={closeUpdateUpModal}
            saveModal={updateProductModal} // You can reuse the same save function or create an update-specific function
            productToEdit={state.selectedProduct} // Pass the selected product to the update modal
            productTypes={state.productTypes}
        />

        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
            <h3>Ապրանքներ</h3>
            {/* <button onClick={openProductModal} className="btn_no_color">
                <FaPlus/>
            </button> */}
        </div>
        <div className="container">
        <div className="row g-2 align-items-center">
            <div className="col-12 col-sm-6 col-md-4">
            <input
                type="text"
                value={state.FProductName}
                onChange={handleProductNameChange}
                onKeyDown={handleKeyDown}
                placeholder="Անուն"
                className="form-control"
            />
            </div>

            <div className="col-12 col-sm-4 col-md-3">
                <div style={{display:"flex",gap:"20px"}}>
                    <select value={state.FProductType} onChange={handleProductTypeChange} className="form-select">
                        <option value={0}>--- Ապրանքի տեսակ ---</option>
                        {state.productTypes.map((type) => (
                            <option key={type.ID} value={type.ID}>
                            {type.Name}
                        </option>
                        ))}
                    </select>
                    <button onClick={() => GiveProduct()} className="btn btn-outline-primary">
                        <FaFilter style={{fontSize:"20px"}}/>
                    </button>
                </div>
            </div>

        </div>
        </div>

        <div style={{overflow:"auto",maxHeight:"500px"}}>
            <Table responsive striped bordered hover className="mt-3">
                <thead>
                    <tr>
                        <th>N</th>
                        <th>Նկար</th>
                        <th>Կոդ</th>
                        <th>Անուն</th>
                        <th>Գին</th>
                        <th>Զեղչված գին</th>
                        <th>Տեսակ</th>
                        <th>Նկարագրություն</th>
                        <th style={{maxWidth:"30px",textAlign:"center"}}>
                            <button onClick={openProductModal} className="btn_no_color">
                                <FaPlus style={{fontSize:"20px"}} />
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {state.products.length === 0 ? (
                        <tr>
                            <td colSpan={8} className="text-center">
                                Ապրանքներ չկան
                            </td>
                        </tr>
                    ) : (
                        state.products.map((product, index) => (
                            <tr key={product.ID}>
                                <td>{index + 1}</td>
                                <td onClick={() => openPhone(product)}>
                                    <img
                                        src={product.ImageURL}
                                        alt={"Նկարը ջնջվել է"}
                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                    />
                                </td>
                                <th>{product.ID}</th>
                                <td>{product.Name}</td>
                                <td>{product.SealPriceAMD}</td>
                                <td>{product.DiscountedPriceAMD}</td>
                                <td>{product.ProductTypeName}</td>
                                <td>{product.Description}</td>
                                <td style={{maxWidth:"30px",textAlign:"center"}}>
                                    <button onClick={() => openProductUpModal(product)} className="btn_no_color">
                                        <FaEdit style={{fontSize:"20px"}}/>
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>

        </div>
    </div>
})