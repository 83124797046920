import React, { useEffect, useState } from "react";
import { IDName } from "../../Types/SaleType";
import { MovmentDoc, MovmentDocProduct } from "../../Types/MovmentType";
import { Axios } from "../../Axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // ✅ Bootstrap ներմուծում ենք
import "./style.scss"
import { User } from "../../Types/Types";
import Swal from "sweetalert2";
import { FaFilter } from "react-icons/fa";


export const Movement: React.FC = React.memo((): JSX.Element => {
  const navigate = useNavigate();

    const [user, setUser] = useState<User>({} as User);
  // ✅ Պահում ենք state-ը մեկ օբյեկտի մեջ
  const [state, setState] = useState({
    warProducts: [] as MovmentDocProduct[],  // Ապրանքների ցուցակ
    productTypes: [] as IDName[],            // Ապրանքի տեսակների ցուցակ
    FProductName: "",                        // Որոնման համար ապրանքի անուն
    FProductType: "",                        // Որոնման համար ապրանքի տեսակի ID
    FProductID: "",                          // Որոնման համար ապրանքի ID
  });
  const [docState, setDocState] = useState<MovmentDoc>({ 
    ID:0,
    DocSumAMD:0,
    DocGiveSumAMD:0,
    CreateUserID:0,
    Notes:"",
    FromID:0,
    ToID:0,
    CassaID:0,
    products: [] as MovmentDocProduct[] 
  });  // Add a products array in docState

  // 📌 Ընդհանուր state թարմացնելու ֆունկցիա
  const updateState = (newValues: Partial<typeof state>) => {
    setState((prevState) => ({ ...prevState, ...newValues }));
  };

  // 📌 API հարցում (Որոնում)
  const fetchProducts = async () => {
    try {
      console.log("Fetching products...");
      const response = await Axios.get("program/movmentproducts", {
        params: {
          StoreID: user.ShopStoreID, // Պարտադիր Store ID
          ProductName: state.FProductName || undefined,
          ProductType: state.FProductType || undefined,
          ProductID: state.FProductID || undefined,
        },
      });

      console.log("Products data:", response.data);
      state.warProducts = response.data;
      updateState({ warProducts: response.data });
    } catch (err) {
      console.error("Fetching products failed", err);
    }
  };

  // 📌 Ներմուծում ենք ապրանքի տեսակները
  const GiveProductTypes = async () => {
    try {
      console.log("Fetching product types...");
      const res = await Axios.get("program/productType");

      console.log("Product types:", res.data);
      if (res?.data?.length > 0) {
        updateState({ productTypes: res.data });
      }
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  // 📌 Ապահովում ենք որ մուտք գործած լինի
  useEffect(() => {
    const checkLogin = async () => {
      try {
        const IsLogin = await Axios.get("auth/check-auth");
        if (IsLogin.data.valid === false) {
          navigate("/"); // Չես մուտք գործել, ուղարկում ենք շուտով
        } else {
          await GiveProductTypes();
          await GiveUser(IsLogin.data.username);
        }
      } catch (error) {
        navigate("/"); // Հաշիվ չկա, ուղարկում ենք տնային էջ
      }
    };

    checkLogin();
  }, []);
  const GiveUser = async (username:string) => {
      const res:any = await Axios.get(`program/user?Username=${username}`);
      if(res.data?.user != null){
        user.ShopStoreID = res.data?.user.ShopStoreID;
          setUser({...res.data?.user});
          setDocState({...docState,ToID:res.data?.user.ShopStoreID,CassaID:res.data?.user.CassaID,CreateUserID :res.data?.user.ID });
      }
  }
  const handleProductClick = (product: MovmentDocProduct) => {
    // Check if the product with the same WarehouseID already exists in docState.products
    const productExists = docState.products.some((existingProduct) => existingProduct.WarehouseID === product.WarehouseID);
    
    if (!productExists) {
      // If the product doesn't exist, add it to the docState.products
      setDocState((prevDocState) => ({
        ...prevDocState,
        products: [...prevDocState.products, {...product,SelectCount:product.ProductCount}],
      }));
    } else {
      // Optionally, you can log or alert the user that the product already exists
      console.log("Product with this WarehouseID already exists.");
    }

  };

  // 📌 Handling Enter key press for search
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      fetchProducts(); // Trigger search when Enter key is pressed
    }
  };

  const handleSaveMovement = async () => {
    try {
      docState.FromID = user.ShopStoreID;
      docState.ToID = 13;
      console.log(docState);
      const response = await Axios.post("program/movmentdoc", docState);
  
      console.log(response);
      if (response.data.success) {
        Swal.fire({
                position:"center",
                icon: "success",
                title: `Տեղափոխման փաստաթուղթը ստեղծված է համար ՝ <b>${response.data.docID}</b>`,
                showConfirmButton: false,
                timer: 1500
            });  
        setDocState({ ...docState, products: [] }); // Մաքրում ենք ապրանքները
        setState({...state,warProducts:[]})
      } else {
        alert("Սխալ տեղի ունեցավ փաստաթուղթը ստեղծելիս");
      }
    } catch (error) {
      console.error("Error creating movement document:", error);
      alert("Սխալ տեղի ունեցավ");
    }
  };
  
  return (
    <div className="container mt-4 Movement">
      <h2 className="text-center mb-4">Ապրանքների որոնում</h2>

      {/* 🟢 Որոնման դաշտեր */}
      <div className="row g-3 mb-4">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Անունով որոնում..."
            value={state.FProductName}
            onChange={(e) => updateState({ FProductName: e.target.value })}
            onKeyDown={handleKeyDown} // Listen for Enter key press
          />
        </div>
        <div className="col-md-4">
          {/* 🟢 Product Type ընտրելու դաշտ */}
          <select
            className="form-select"
            value={state.FProductType}
            onChange={(e) => updateState({ FProductType: e.target.value })}
          >
            <option value="">--- Ապրանքի տեսակ ---</option>
            {state.productTypes.map((type) => (
              <option key={type.ID} value={type.ID}>
                {type.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
            <div style={{display:"flex",gap:"30px"}}>
              <input
                type="text"
                className="form-control"
                placeholder="ID-ով որոնում..."
                value={state.FProductID}
                onChange={(e) => updateState({ FProductID: e.target.value })}
                onKeyDown={handleKeyDown} // Listen for Enter key press
              />
              <button className="btn btn-primary" onClick={fetchProducts}>
                <FaFilter />
              </button>
            </div>
          </div>
      </div>


      {/* 📌 Ապրանքների ցուցադրում (Table) */}
      {state.warProducts.length > 0 ? (
        <div className="table-responsive" style={{overflow:"auto",height:"300px"}}>
          <table className="table table-bordered table-hover text-center">
            <thead className="table-dark">
              <tr>
                <th>Կոդ</th>
                <th>Նկար</th>
                <th>Անուն</th>
                <th>Վաճառքի գին (AMD)</th>
                <th>Ինքնարժեք 1 (AMD)</th>
                <th>Ինքնարժեք 2 (AMD)</th>
                <th>Քանակ</th>
              </tr>
            </thead>
            <tbody>
              {state.warProducts.map((product) => (
                <tr key={product.WarehouseID} onClick={() => handleProductClick(product)} 
                  className={docState.products.find(e=>e.WarehouseID==product.WarehouseID)? "tr_bg" : ""}>
                    <th>{product.ProductID}</th>
                  <td>
                    <img
                      src={product.ImageURL}
                      alt={product.ProductName}
                      style={{ width: "50px", height: "50px", objectFit: "cover" }}
                    />
                  </td>
                  <td>{product.ProductName}</td>
                  <td>{product.SellPriceAMD}</td>
                  <td>{product.ProductCost1AMD}</td>
                  <td>{product.ProductCost2AMD}</td>
                  <td>{product.ProductCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center mt-3">Տվյալներ չկան</p>
      )}

        {docState.products.length > 0 && (
          <>
                <h3 className="text-center mb-4">Ընտրված ապրանքներ</h3>
                <div className="mt-4" style={{overflow:"auto",height:"300px"}}>
                    <div className="table-responsive">
                    <table className="table table-bordered table-hover text-center">
                        <thead className="table-dark">
                        <tr>
                            <th>Կոդ</th>
                            <th>Նկար</th>
                            <th>Անուն</th>
                            <th>Վաճառքի գին (AMD)</th>
                            <th>Քանակ</th>
                            <th>Ընտրած քանակ</th> {/* New column for selecting quantity */}
                        </tr>
                        </thead>
                        <tbody>
                        {docState.products.map((product, index) => (
                            <tr key={index}>
                              <td>{product.ProductID}</td>
                            <td>
                                <img
                                src={product.ImageURL}
                                alt={product.ProductName}
                                style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                />
                            </td>
                            <td>{product.ProductName}</td>
                            <td>{product.SellPriceAMD}</td>
                            <td>{product.ProductCount}</td>
                            {/* SelectCount field with input type number */}
                            <td>
                            <input
                                type="number"
                                className="form-control"
                                //min="1"
                                //max={product.ProductCount} // Max is set to the available quantity
                                value={product.SelectCount == 0 ? "" : product.SelectCount} // Use SelectCount if it exists, otherwise ProductCount
                                onChange={(e) => {
                                    const newValue = parseInt(e.target.value, 10);
                                    console.log(newValue);

                                    // Ensure the new value does not exceed the available ProductCount
                                    if (newValue <= product.ProductCount && newValue > 0) {
                                    // Create a copy of the products array and update the product's SelectCount
                                    const updatedProducts = [...docState.products];
                                    updatedProducts[index] = {
                                        ...updatedProducts[index],
                                        SelectCount: newValue,
                                    };

                                    // Update the docState with the new products array
                                    setDocState({ ...docState, products: updatedProducts });
                                    } else {
                                    // Optionally, handle invalid values (e.g., exceeding ProductCount)
                                    console.log("Cannot select more than the available quantity.");
                                    }
                                }}
                                style={{ width: "80px" }}
                                />

                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                </>
                )}
                <br/>
                {
                  docState.products.length > 0 ?
                  <>
                  <button className="btn btn-success mt-3" onClick={handleSaveMovement}>
                    Ստեղծել տեղափոխման փաստաթուղթ
                  </button>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </>
                :
                <>
                <button className="btn btn-success mt-3" onClick={handleSaveMovement}>
                    Ստեղծել տեղափոխման փաստաթուղթ
                  </button>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </>
                }
    </div>
    
  );
});
