import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { Axios } from "../../Axios";
import { Helpers } from "../../Helpers";
import { User } from "../../Types/Types";
import moment from "moment";
import { FaSearch } from "react-icons/fa";

export const CassaSum: React.FC = React.memo((): JSX.Element => {
  const [user, setUser] = useState({} as User);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [state, setState] = useState({
    payments: [],
    allPayments: 0,
    todayPayments: 0, // ✅ Ավելացրել ենք այսօրվա գումարը
  });

  const navigate = useNavigate();

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const IsLogin = await Axios.get("auth/check-auth");
        if (!IsLogin.data.valid) {
          navigate("/");
        } else {
          await GiveUser(IsLogin.data.username);
          await handleSearchPayments(); // ✅ Կանչում ենք տվյալների որոնումը
        }
      } catch (error) {
        navigate("/");
      }
    };

    checkLogin();
  }, []);

  const GiveUser = async (username: string) => {
    const res: any = await Axios.get(`program/user?Username=${username}`);
    if (res.data?.user) {
      user.CassaID = res.data?.user.CassaID;
      setUser({ ...res.data?.user });
    }
  };

  const handleSearchPayments = async () => {
    try {
      let queryParams = `CassaID=${encodeURIComponent(user.CassaID)}&startDate=${startDate}&endDate=${endDate}`;
      let res: any = await Axios.get(`program/cassaSum?${queryParams}`);
      console.log(user.CassaID,startDate,endDate,res);
      setState({
        payments: res.data.payments,
        allPayments: res.data.allPayments || 0,
        todayPayments: res.data.todayPayments || 0, // ✅ Ավելացնում ենք այսօրվա վճարումները
      });
    } catch (error) {
      console.error("Error fetching payments data:", error);
      Swal.fire("Սխալ", "Վճարումների որոնման ընթացքում սխալ առաջացավ", "error");
    }
  };

  return (
    <div className="Income">
      <div className="income_sum">
        <div>
          <span style={state.todayPayments < 0 ? { color: "red" } : { color: "green" }}>
            {Helpers.formatNumberText(state.todayPayments)}
          </span>
        </div>
        <div>
          <span style={state.allPayments < 0 ? { color: "red" } : { color: "green" }}>
            {Helpers.formatNumberText(state.allPayments)}
          </span>
        </div>
      </div>

      <hr style={{ margin: "10px" }} />
      <div className="date-filters d-flex align-items-center gap-2 mb-3">
        <span>Ա1</span>
        <input type="date" className="form-control w-auto" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <span>Ա2</span>
        <input type="date" className="form-control w-auto" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <button onClick={handleSearchPayments} className="btn_col">
          <FaSearch />
        </button>
      </div>
      <div style={{ overflow: "auto", maxHeight: "420px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Անուն</th>
              <th>Գումար</th>
              <th>Ամսաթիվ</th>
            </tr>
          </thead>
          <tbody>
            {state.payments.map((data: any) => (
              <tr key={data.DocID}>
                <td>{data.LPName}</td>
                <td style={{ fontWeight: "700" }}>
                  <span style={data.DocSum >= 0 ? { color: "green" } : { color: "red" }}>
                    {Helpers.formatNumberText(data.DocSum)}
                  </span>
                </td>
                <td>{data.Dtn}</td>
              </tr>
            ))}
          </tbody>
          {
            state.payments.length > 1
            ?
            <tfoot>
            <tr>
              <th>Ընդամենը</th>
              <th style={state.payments.reduce((a:any,b:any)=>a + +b.DocSum,0) >= 0 ? { color: "green" } : { color: "red" }}>
                {Helpers.formatNumberText(state.payments.reduce((a:any,b:any)=>a + +b.DocSum,0))}</th>
            </tr>
          </tfoot>
          :
          <></>
          }
        </Table>
      </div>
    </div>
  );
});
