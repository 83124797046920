import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { Table, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { Axios } from "../../Axios";
import { Helpers } from "../../Helpers";
import { User } from "../../Types/Types";
import moment from "moment";
import { FaSearch } from "react-icons/fa";

export const SalesHistory: React.FC = React.memo(() => {
  const [user, setUser] = useState<User | null>(null);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [state, setState] = useState({
    sales: [],
    totalSales: 0,
    todaySales: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const IsLogin = await Axios.get("auth/check-auth");
        if (!IsLogin.data.valid) {
          navigate("/");
          return;
        }

        const res = await Axios.get(`program/user?Username=${IsLogin.data.username}`);
        if (res.data?.user) {
          setUser(res.data.user);
          await handleSearchSales(res.data.user.CassaID);
        }
      } catch (error) {
        navigate("/");
      }
    };

    checkLogin();
  }, []);

  const handleSearchSales = useCallback(
    async (cassaID: number) => {
      setLoading(true);
      setError(null);
      try {
        const queryParams = `CassaID=${encodeURIComponent(cassaID)}&startDate=${startDate}&endDate=${endDate}`;
        const res = await Axios.get(`program/getsales?${queryParams}`);
        console.log("Fetched Data:", res);

        setState({
          sales: res.data.sales,
          totalSales: res.data.totalSales || 0,
          todaySales: res.data.todaySales || 0,
        });
      } catch (error) {
        console.error("Error fetching sales data:", error);
        setError("Վաճառքների որոնման ընթացքում սխալ առաջացավ");
      } finally {
        setLoading(false);
      }
    },
    [startDate, endDate]
  );

  if (!user) return <div>Բեռնվում է...</div>;

  return (
    <div className="Income">
      <div className="income_sum">
        <div>
          <span style={{ color: state.todaySales < 0 ? "red" : "green" }}>
            {Helpers.formatNumberText(state.todaySales)}
          </span>
        </div>
        <div>
          <span style={{ color: state.totalSales < 0 ? "red" : "green" }}>
            {Helpers.formatNumberText(state.totalSales)}
          </span>
        </div>
      </div>

      <hr style={{ margin: "10px" }} />

      <div className="date-filters d-flex align-items-center gap-2 mb-3">
        <span>Ա1</span>
        <input type="date" className="form-control w-auto" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <span>Ա2</span>
        <input type="date" className="form-control w-auto" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <button  onClick={() => handleSearchSales(user.CassaID)} className="btn_col">
          <FaSearch />
        </button>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      <div style={{ overflow: "auto", maxHeight: "420px" }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Հաճախորդ</th>
              <th>Գումար</th>
              <th>Ամսաթիվ</th>
            </tr>
          </thead>
          <tbody>
            {state.sales.map((data: any) => (
              <tr key={data.DocID}>
                <td>{data.ToName}</td>
                <td style={{ fontWeight: "700", color: data.DocSumAMD >= 0 ? "green" : "red" }}>
                  {Helpers.formatNumberText(data.DocSumAMD)}
                </td>
                <td>{data.Dtn}</td>
              </tr>
            ))}
          </tbody>
          {
            state.sales.length > 1
            ?
            <tfoot>
            <tr>
              <th>Ընդամենը</th>
              <th style={{ color: state.sales.reduce((a: any, b: any) => a + +b.DocSumAMD, 0) >= 0 ? "green" : "red" }}>
                {Helpers.formatNumberText(state.sales.reduce((a: any, b: any) => a + +b.DocSumAMD, 0))}
              </th>
            </tr>
          </tfoot>
          :
          <></>
          }
        </Table>
      </div>
    </div>
  );
});
