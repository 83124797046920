import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { ProductCU } from "../../Types/ProductType";
import { IDName } from "../../Types/SaleType";
import { Axios } from "../../Axios";
import { Button, Table } from "react-bootstrap";
import { FaCheck, FaFilter, FaTrash } from "react-icons/fa";
import { PurchaseDoc, PurchaseDocProduct, SearchPurchaseDoc } from "../../Types/PurchaseType";
import { Helpers } from "../../Helpers";
import { User } from "../../Types/Types";


export const Purchase: React.FC = React.memo((): JSX.Element => {
    const navigate = useNavigate()
    const [user, setUser] = useState<User>({} as User);
    const [state, setState] = useState({
        products: [] as ProductCU[],
        searchPurchase:[] as SearchPurchaseDoc[],
        productTypes: [] as IDName[],
        organizations: [] as IDName[],
        stores: [] as IDName[],
        cassas: [] as IDName[],
        FProductType:0,
        FProductName:"",
        IsShowSearch:true as boolean
    });

    const [docState, setDocState] = useState<PurchaseDoc>({
            ID:0,
            DocSumAMD: 0,
            DocGiveSumAMD: 0, // Հաճախոդի վճարած գին
            CreateUserID: 0, // User ID
            Notes: "", // Այլ տվյալներ
            FromID:0, // Խանութ
            ToID:0 , // Հաճախորդի
            CassaID:0, // Կասսա
            products:[], // Ապրանքների ցուցակ
            IsAccept:false
    });

    // USER CHECK
    useEffect(() => {
        const checkLogin = async () => {
                try {
                    const IsLogin = await Axios.get("auth/check-auth");
                    if(IsLogin.data.valid == false)
                    {
                        navigate("/");
                    }
                    else
                    {
                        await GiveProductTypes();
                        await GiveOrganizations();
                        await GiveShopstore();
                        await GiveCassa();
                        await GiveUser(IsLogin.data.username);
                        await GivePurchaseDoc();
                    }
                    } catch (error) {
                    navigate("/");
                }
            };
            
            checkLogin();
    }, [])
    const GiveUser = async (username:string) => {
        const res:any = await Axios.get(`program/user?Username=${username}`);
        if(res.data?.user != null){
            setUser({...res.data?.user});
            setDocState({...docState,ToID:res.data?.user.ShopStoreID,CassaID:res.data?.user.CassaID});
        }
    }
    const GiveProductTypes = async () => {
        const res = await Axios.get(`program/productType`);
        if (res?.data?.length > 0) {
            setState(prevState => ({
                ...prevState,
                productTypes: res?.data
            }));
        }
    }
    const GivePurchaseDoc = async () =>{
        const res = await Axios.get(`program/allpurchase`)
        if(res.data){
            if (res?.data?.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    searchPurchase: res?.data
                }));
            }
        }
        
    }
    const GiveOrganizations = async () => {
        const res = await Axios.get(`program/organizations`);
        if (res?.data?.length > 0) {
            setState(prevState => ({
                ...prevState,
                organizations: res?.data
            }));
        }
    }
    const GiveShopstore = async () => {
        const res = await Axios.get(`program/shopstore`);
        if (res?.data?.length > 0) {
            setState(prevState => ({
                ...prevState,
                stores: res?.data
            }));
        }
    }
    const GiveCassa = async () => {
        const res = await Axios.get(`program/cassa`);
        if (res?.data?.length > 0) {
            setState(prevState => ({
                ...prevState,
                cassas: res?.data
            }));
        }
    }
    const GiveProduct = async () => {
        const res = await Axios.get(`product?Name=${state.FProductName}&ProductType=${state.FProductType}`);
        if (res?.data?.length > 0) {
            setState(prevState => ({
                ...prevState,
                products: res?.data
            }));
        }
    }
        const openPhone = (data : ProductCU) => {
            Swal.fire({
                title: "",
                text: "",
                imageUrl: data.ImageURL,
                imageWidth: 400,
                imageHeight: 400,
                imageAlt: "Նկարը բացակայում է",
                confirmButtonText : "Փակել"
              });
        }
        const openPhoneP = (data : PurchaseDocProduct) => {
            Swal.fire({
                title: "",
                text: "",
                imageUrl: data.ImageURL,
                imageWidth: 400,
                imageHeight: 400,
                imageAlt: "Նկարը բացակայում է",
                confirmButtonText : "Փակել"
              });
        }
    const SelectProduct = async (data : ProductCU) => {
        const newProductP:PurchaseDocProduct = {
            DocProductID: Date.now(),
            ProductID:data.ID,
            ProductName:data.Name,
            ImageURL: data.ImageURL,
            SellPriceAMD:data.SealPriceAMD,
            ProductPriceAMD:0,
            ProductPriceAMDSum:0,
            ProductCount:0,
            ProductCost1AMD:0,
            ProductCost2AMD:0,
            IsGift:false
        }
        setDocState(
            {
                ...docState,
                products:[...docState.products,newProductP]
            }
        )
        Swal.fire({
            title: "Ապրանքը ավելացվեց",
            icon: "info",
            timer: 1000, // 1 վայրկյան հետո փակվում է
            showConfirmButton: false,
            position: "top-end", // Վերևի աջ անկյուն
            toast: true, // Փոքր նոթիֆիկացիա
        });
    
    
        // console.log(docState.products)
    }
    const DeleteProduct = (product:PurchaseDocProduct) => {
        console.log(product )
        docState.products = docState.products.filter(e => e.DocProductID != product.DocProductID); 
        docState.DocSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)
        docState.DocGiveSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)

        Swal.fire({
            title: "Հաջողությամբ ջնջվեց",
            icon: "info",
            timer: 1000, // 1 վայրկյան հետո փակվում է
            showConfirmButton: false,
            position: "top-end", // Վերևի աջ անկյուն
            toast: true, // Փոքր նոթիֆիկացիա
        }); 
        
        setDocState({...docState});
    }
    /// 
    const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => ({
            ...prevState,
            FProductName: event.target.value
        }));
    };
    
    const handleProductTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setState((prevState) => ({
            ...prevState,
            FProductType: Number(event.target.value) // Ensure it's a number
        }));
    };
    const handleDocPartnerchange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDocState((prevState) => ({
            ...prevState,
            FromID: Number(event.target.value) // Ensure it's a number
        }));
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            GiveProduct(); // Call the function when Enter is pressed
        }
    };

    const handleChange = (e : any,product:PurchaseDocProduct, Type : number) => {
        console.log(e,Type);
        const NewCount = Type == 1?parseFloat(e.target.value) || 0 : product.ProductCount;
        const NewSum = Type == 2 ? parseFloat(e.target.value) || 0 : product.ProductPriceAMDSum ;

        if(NewCount != 0 &&  NewSum != 0){
            const updatedProduct : PurchaseDocProduct = {
                ...product,
                ProductCount: NewCount,
                ProductPriceAMDSum: NewSum,
                ProductPriceAMD: Helpers.roundToTwoDecimal(NewSum / NewCount),
                ProductCost1AMD:Helpers.roundToTwoDecimal(NewSum / NewCount),
                ProductCost2AMD:Helpers.roundToTwoDecimal(NewSum / NewCount)
            };
            const index:number = docState.products.findIndex(e=>e.DocProductID == product.DocProductID);
            docState.products[index] = updatedProduct;
            docState.DocSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)
            docState.DocGiveSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)
            setDocState({...docState})
        }else{
            const updatedProduct: PurchaseDocProduct= {
                ...product,
                ProductCount: NewCount,
                ProductPriceAMDSum: NewSum,
                ProductPriceAMD: 0,
                ProductCost1AMD: 0,
                ProductCost2AMD: 0
            };
            docState.DocSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)
            docState.DocGiveSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)
            const index:number = docState.products.findIndex(e=>e.DocProductID == product.DocProductID);
            docState.products[index] = updatedProduct;
            setDocState({...docState})
        }
    };

    const handleChangeProductCost2 = (e : any,product:PurchaseDocProduct) => {
        const NewCost = Helpers.roundToTwoDecimal(parseFloat(e.target.value)) ;
        const updatedProduct: PurchaseDocProduct = {
            ...product,
            ProductCost2AMD: NewCost,
        };
        const index:number = docState.products.findIndex(e=>e.DocProductID == product.DocProductID);
        docState.products[index] = updatedProduct;
        setDocState({...docState})
    }
    const handleChangeIsGift = (IsGift:boolean,product:PurchaseDocProduct) => {
        const index:number = docState.products.findIndex(e=>e.DocProductID == product.DocProductID);
        docState.products[index].IsGift = IsGift;
        docState.products[index].ProductCost1AMD = IsGift == true?0:docState.products[index].ProductPriceAMD;

        docState.DocSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)
        docState.DocGiveSumAMD = docState.products.filter(e=>!e.IsGift).reduce((a,b) => a + b.ProductPriceAMDSum,0)
        setDocState({...docState})
    }
    const handleChangeDocGiveSum = (e : any) => {
        const NewSum = parseFloat(e.target.value) || 0;
        docState.DocGiveSumAMD = NewSum;
        const Desc = docState.DocGiveSumAMD *  100/docState.DocSumAMD
        for(let i = 0;i < docState.products.length; i++)
        {
            if(docState.products[i].IsGift == false)
            {
                docState.products[i].ProductCost1AMD = Helpers.roundToTwoDecimal(docState.products[i].ProductPriceAMD * Desc / 100)
            }
        }
        setDocState({...docState});
    }

    const GivePurchaseByID = async (e: SearchPurchaseDoc) => {
        try {
          const response = await Axios.get(`program/getPurchaseDoc/${e.ID}`);
          console.log(response.data.products)
          if(response?.data){
              setDocState({...response.data}); // Թարմացնում ենք state-ը
            }
        } catch (error) {
          console.error("Error fetching purchase document:", error);
        }
      };
      
    const CreatePurchaseDoc = async () => {
        if(docState.FromID == 0 ){
            Swal.fire({
                icon:"error",
                text: "Մատակարարը ընտրված չէ!",
                confirmButtonText : "Լավ"
            });
            return;
        }
        if(docState.products.length == 0 ){
            Swal.fire({
                icon:"error",
                text: "Ապրանքները ընտրված չեն!",
                confirmButtonText : "Լավ"
            });
            return;
        }
        console.log(user);
        const result = await Swal.fire({
              title: "Առք կատարե՞լ",
              text: "Համոզվա՞ծ եք, որ ցանկանում եք առք կատարել",
              icon: "question",
              showCancelButton: true,
              confirmButtonText: "Այո",
              cancelButtonText: "Ոչ",
            });
          
            if (!result.isConfirmed) {
              return; // Եթե օգտատերը չհաստատի, դուրս ենք գալիս ֆունկցիայից
            }
        docState.CassaID = user.CassaID;
        docState.CreateUserID = user.ID;
        const res:any = await Axios.post(`program/createPurchaseDoc`,docState);
        if(res.data?.docID && res.data?.docID != 0){
            Swal.fire({
                position:"center",
                icon: "success",
                title: docState.ID == 0 ?"Առքը հաջողությամբ ստեղծվեց": "Առքը հաջողությամբ խմբագրվեց",
                showConfirmButton: false,
                timer: 1500
            });     
            setDocState({...docState,ID:res.data?.docID});
            await GivePurchaseDoc();

        }
        else{
            Swal.fire({
                icon:"error",
                text: docState.ID == 0  ? "Առքը չհաջողվեց ստեղծել!" : "Առքը չհաջողվեց խմբագրել!",
                confirmButtonText : "Լավ"
              });    
        }
        console.log(res);
    }

    const CreatePurchaseDocIsAccept = async () => {
        if(docState.FromID == 0 ){
            Swal.fire({
                icon:"error",
                text: "Մատակարարը ընտրված չէ!",
                confirmButtonText : "Լավ"
            });  
            return;
        }
        docState.IsAccept = true;
        docState.CassaID = user.CassaID;
        docState.CreateUserID = user.ID;
        const res:any = await Axios.post(`program/createPurchaseDoc`,docState);
        if(res.data?.docID && res.data?.docID != 0){
            Swal.fire({
                position:"center",
                icon: "success",
                title: "Առքը ընդունվեց պահեստ",
                showConfirmButton: false,
                timer: 1500
            });     
            setDocState({...docState,ID:res.data?.docID,IsAccept:true});
            await GivePurchaseDoc();
        }
        else{
            setDocState({...docState,IsAccept:false});
            Swal.fire({
                icon: "error",
                text: "Առքը չհաջողվեց ընդունվել պահեստ!" ,
                confirmButtonText : "Լավ"
              });    
        }
        console.log(res);
    }
    return <div className="Purchase">
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <h4>Ընթացիկ առքեր 
                    <button className="btn_c" style={{fontWeight:"700",fontSize:"30px",marginLeft:"10px"}} onClick={()=>setState({...state,IsShowSearch:!state.IsShowSearch})}>
                        {state.IsShowSearch ? "-" : "+"}
                        </button> </h4>
            </div> 
            {
                state.IsShowSearch
                ?
                <div style={{overflow:"auto",maxHeight:"300px"}}>
                <Table responsive striped bordered hover className="mt-3">
                    <thead>
                        <tr>
                            <th>N</th>
                            <th>Համար</th>
                            <th>Մատակարար</th>
                            <th>Պահեստ</th>
                            <th>Գումար</th>
                            <th>Վճարված գումար</th>
                            <th>Ամսաթիվը</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.searchPurchase.length === 0 ? (
                            <tr>
                                <td colSpan={8} className="text-center">
                                    Առքր չկան
                                </td>
                            </tr>
                        ) : (
                            state.searchPurchase.map((data, index) => (
                                <tr key={data.ID}  >
                                    <td>{index + 1}</td>
                                    <td onClick={() => GivePurchaseByID(data)}>{data.DocNumber}</td>
                                    <td>{data.OrgName}</td>
                                    <td>{data.StoreName}</td>
                                    <td>{data.DocSumAMD}</td>
                                    <td>{data.DocGiveSum}</td>
                                    <td>{data.CreateDtn.toString()}</td>
                                </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
                :
                <></>
            }
            
            <hr/>
            <div className="product_search" style={{display:"flex",gap:"10px"}}>
                <select value={docState.FromID} onChange={handleDocPartnerchange}
                   className="border rounded px-3 py-2" style={{width:"250px"}}>
                    <option value={0}>---- Մատակարար ----</option>
                    {state.organizations.map((type) => (
                        <option key={type.ID} value={type.ID}>
                            {type.Name}
                        </option>
                    ))}
                </select>
                <select value={docState.ToID} onChange={handleProductTypeChange}
                   className="border rounded px-3 py-2" style={{width:"250px"}}>
                    <option value={0}>---- Խանութ -----</option>
                    {state.stores.map((type) => (
                        <option key={type.ID} value={type.ID}>
                            {type.Name}
                        </option>
                    ))}
                </select>
                <select value={docState.CassaID} onChange={handleProductTypeChange}
                   className="border rounded px-3 py-2" style={{width:"250px"}}>
                    <option value={0}>---- Դրամարկղ ----</option>
                    {state.cassas.map((type) => (
                        <option key={type.ID} value={type.ID}>
                            {type.Name}
                        </option>
                    ))}
                </select>

            </div>
            <hr/>
            <div className="product_search" style={{display:"flex",gap:"20px"}}>
                <input
                    type="text"
                    value={state.FProductName}
                    onChange={handleProductNameChange}
                    onKeyDown={handleKeyDown} // Listen for Enter key
                    placeholder="Անուն"
                    className="border rounded px-3 py-2 w-64"
                />

                <select value={state.FProductType} onChange={handleProductTypeChange} className="border rounded px-3 py-2">
                    <option value={0}></option>
                    {state.productTypes.map((type) => (
                        <option key={type.ID} value={type.ID}>
                            {type.Name}
                        </option>
                    ))}
                </select>
                <button onClick={() => GiveProduct()} className="btn_no_color">
                    <FaFilter color="blue" />
                </button>
            </div>
            <div className="product" style={{overflow:"auto",maxHeight:"300px"}}>
                <Table responsive striped bordered hover className="mt-3">
                    <thead>
                        <tr>
                            <th>N</th>
                            <th>Նկար</th>
                            <th>Կոդ</th>
                            <th>Ապրանքի անուն</th>
                            <th>Գին</th>
                            <th>Զեղչված գին</th>
                            <th>Տեսակ</th>
                            <th>Նկարագրություն</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.products.length === 0 ? (
                            <tr>
                                <td colSpan={8} className="text-center">
                                    Ապրանքներ չկան
                                </td>
                            </tr>
                        ) : (
                            state.products.map((product, index) => (
                                <tr key={product.ID}  >
                                    <td>{index + 1}</td>
                                    <td onClick={() => openPhone(product)}>
                                        <img
                                            src={product.ImageURL}
                                            alt={"Նկարը ջնջվել է"}
                                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                        />
                                    </td>
                                    <td>{product.ID}</td>

                                    <td onClick={() => SelectProduct(product)}>
                                            {product.Name}
                                    </td>
                                    <td>{product.SealPriceAMD}</td>
                                    <td>{product.DiscountedPriceAMD}</td>
                                    <td>{product.ProductTypeName}</td>
                                    <td>{product.Description}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
            <div>
                <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                    <h3>Առքի ապրանքները</h3>
                </div> 
            </div>
            <div className="product_P" style={{ maxHeight: "400px", overflow: "auto" }}>
                <Table responsive striped bordered hover className="mt-3" >
                    <thead>
                        <tr>    
                            <th>N</th>
                            <th>Նկար</th>
                            <th>Կոդ</th>
                            <th>Ապրանքի անուն</th>
                            <th>Վաճ Գին</th>
                            <th>Առքի գումար</th>
                            <th>Քանակ</th>
                            <th>Առքի գին</th>
                            <th>Ինքնարժեք 1</th>
                            <th>Ինքնարժեք 2</th>
                            <th>Նվեր է</th>
                            <th>Ջնջել</th>
                        </tr>
                    </thead>
                    <tbody >
                        {docState.products.length === 0 ? (
                            <tr>
                                <td colSpan={11 } className="text-center">
                                    Առքի ապրանքներ չկան
                                </td>
                            </tr>
                        ) : (
                            docState.products.map((product, index) => (
                                <tr key={product.DocProductID}  >
                                    <td>{index + 1}</td>
                                    <td onClick={() => openPhoneP(product)}>
                                        <img
                                            src={product.ImageURL}
                                            alt={"Նկարը ջնջվել է"}
                                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                        />
                                    </td>
                                    <td >
                                            {product.ProductID}
                                    </td>
                                    <td >
                                            {product.ProductName}
                                    </td>
                                    <td>{product.SellPriceAMD}</td>
                                        <td>
                                            <input
                                            className="form-control form-control-sm text-center"

                                                type="number"
                                                value={product.ProductPriceAMDSum == 0 ? "" : product.ProductPriceAMDSum}
                                                onChange={(e) => handleChange(e, product,2)}
                                                style={{ width: "120px" }}
                                            />
                                        </td>

                                        <td>
                                            <input
                                            className="form-control form-control-sm text-center"
                                                type="number"
                                                value={product.ProductCount == 0 ? "" : product.ProductCount}
                                                onChange={(e) => handleChange(e, product,1)}
                                                style={{ width: "70px" }}
                                            />
                                        </td>

                                    <td>{product.ProductPriceAMD}</td>
                                    
                                    <td>{product.ProductCost1AMD}</td>
                                    <td>
                                        <input  
                                            className="form-control form-control-sm text-center"
                                                type="number"
                                                value={product.ProductCost2AMD == 0 ? "" :product.ProductCost2AMD}
                                                onChange={(e) => handleChangeProductCost2(e, product)}
                                                style={{ width: "120px" }}
                                            />
                                    </td>

                                    <td>
                                        <input  
                                            className="form-check-input custom-checkbox"
                                            style={{width:"25px",height:"25px"}}
                                            type="checkbox"
                                            checked={product.IsGift}  // Use "checked" instead of "value"
                                            onChange={(e) => handleChangeIsGift(e.target.checked, product)}
                                        />
                                    </td>

                                    <td>
                                        <button onClick={() => DeleteProduct(product)} className="btn_no_color">
                                            <FaTrash  color="red" />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4}>Ընդամենը</th>
                            <th>{docState.products.reduce((a,b)=>a + +b.SellPriceAMD * b.ProductCount,0)}</th>
                            <th>{docState.products.reduce((a,b)=>a + +b.ProductPriceAMDSum,0)}</th>
                            <th>{docState.products.reduce((a,b)=>a + +b.ProductCount,0)}</th>
                            <th></th>
                            <th>{docState.products.reduce((a,b)=>a + +b.ProductCount * b.ProductCost1AMD,0)}</th>
                            <th>{docState.products.reduce((a,b)=>a + +b.ProductCount * b.ProductCost2AMD,0)}</th>
                            <th></th>
                            <th></th>
                        </tr>   
                    </tfoot>
                </Table>
            </div>
            <hr/>
            <div style={{display:"flex",gap:"20px"}}>
                <span>Վաճառքի գումար</span>
                <input  
                    className="form-control form-control-sm text-center"
                        disabled
                        type="number"
                        value={docState.DocSumAMD}
                        style={{ width: "120px" }}
                    />
                <span>Վճարված գումար</span>
                <input  
                    className="form-control form-control-sm text-center"
                        type="number"
                        onChange={(e)=> handleChangeDocGiveSum(e)}
                        value={docState.DocGiveSumAMD == 0 ? "" : docState.DocGiveSumAMD}
                        style={{ width: "120px" }}
                    />
                {
                    docState.IsAccept ?
                    <div>
                        <Button variant="dark" onClick={CreatePurchaseDoc} 
                            style={{ width: '200px',color:"white",height:"35px" }}>
                            Հետ պահեստից 
                        </Button>
                    </div>
                    :
                    <div style={{display:"flex",gap:"20px"}}>
                        
                        <Button variant="primary" onClick={CreatePurchaseDoc} 
                            style={{ width: '150px',color:"white",height:"35px" }}>
                            Առք {docState.ID == 0 ? "Ստեղծել" : "Խմբագրել"}
                        </Button>

                        <Button variant="success" onClick={CreatePurchaseDocIsAccept} 
                            style={{ width: '200px',color:"white",height:"35px" }}>
                            Ընդունել պահեստ
                        </Button>
                    </div>
                }
            </div>
            <br/>
    </div>
})