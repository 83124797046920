import React, { useEffect, useState } from "react";
import { Axios } from "../../Axios";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCheckCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ReceptionDoc, ReceptionDocProduct } from "../../Types/ReceptionType";
import { useNavigate } from "react-router-dom";
import { User } from "../../Types/Types";
import './style.scss'

export const Reception: React.FC = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState<User>({} as User);
  const [docs, setDocs] = useState<ReceptionDoc[]>([]);
  const [expandedDoc, setExpandedDoc] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch reception documents from API
  const fetchReceptionDocs = async () => {
    setLoading(true);
    try {
      console.log(user.ShopStoreID)
      const response = await Axios.get(`/program/movmentdoc?ToID=${user.ShopStoreID}`);
      console.log(response)
      setDocs(response.data);
    } catch (error) {
      Swal.fire("Սխալ", "Տվյալները բեռնելու ընթացքում ունեցավ սխալ", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkLogin = async () => {
                    try {
                        const IsLogin = await Axios.get("auth/check-auth");
                        if(IsLogin.data.valid == false)
                        {
                            navigate("/");
                        }
                        else
                        {
                          await GiveUser(IsLogin.data.username);
                          fetchReceptionDocs();
                        }
                        } catch (error) {
                        navigate("/");
                    }
                };
                
                checkLogin();
  }, []);

  const GiveUser = async (username:string) => {
          const res:any = await Axios.get(`program/user?Username=${username}`);
          if(res.data?.user != null){
              user.ShopStoreID = res.data?.user.ShopStoreID;
              setUser({...res.data?.user});
              // setDocState({...docState,ToID:res.data?.user.ShopStoreID,CassaID:res.data?.user.CassaID});
          }
      }

  // Toggle expansion of document details
  const toggleDocDetails = async (docID: number) => {
    if (expandedDoc === docID) {
      setExpandedDoc(null);
    } else {
      let index = docs.findIndex(e=>e.DocID == docID);
      if(index != -1)
      {
        const response = await Axios.get(`/program/movmentdocdetails?DocID=${docID}`);
        if(response.data){
          docs[index].products = response.data;
          setExpandedDoc(docID);
        }
      }
    }
  };

  // Open product image in a modal
  const openPhone = (data: ReceptionDocProduct) => {
    Swal.fire({
      title: "",
      text: "",
      imageUrl: data.ImageURL,
      imageWidth: 400,
      imageHeight: 400,
      imageAlt: "Նկարը բացակայում է",
      confirmButtonText: "Փակել",
    });
  };
  const createReceptionDoc = async(receptionDoc:ReceptionDoc)=> {
    try {

      const result = await Swal.fire({
            title: "Ընդունում կատարե՞լ",
            text: "Համոզվա՞ծ եք, որ ցանկանում եք ընդունել ապրանքները",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Այո",
            cancelButtonText: "Ոչ",
          });
        
          if (!result.isConfirmed) {
            return; // Եթե օգտատերը չհաստատի, դուրս ենք գալիս ֆունկցիայից
          }
      receptionDoc.StoreID = user.ShopStoreID;
      const response = await Axios.post("program/createReceptionDoc", receptionDoc);
      console.log("Փաստաթուղթ ստեղծվեց հաջողությամբ:", response.data);
      if(response.data?.docID && response.data?.docID != 0){
          setDocs([...docs.filter(e=>e.DocID != receptionDoc.DocID)])
            Swal.fire({
                position:"center",
                icon: "success",
                title: "Ապրանքը հաջողությամբ ընդունվեց ձեզ մոտ",
                showConfirmButton: false,
                timer: 1500
            });     
        }
      return response.data; // Վերադարձնում է docID-ն կամ հաջողության մասին info
    } catch (error:any) {
      
      Swal.fire({
                  icon: "error",
                  text: "Չհաջողվեց ընդունել!" ,
                  confirmButtonText : "Լավ"
                }); 
      return null; // Կամ կարող ես սխալի մասին վերադարձնել error object
    }
  }
  
  return (
    <div className="container mt-4 Reception">
      {/* <h2 className="text-center mb-4">Reception Փաստաթղթեր</h2> */}

      {/* Show a loading spinner while data is being fetched */}
      {loading && <div className="text-center">Բեռնում...</div>}


      <div className="table-responsive">
      {
        docs.length > 0
        ?
        <table className="table table-bordered table-hover text-center">
          <thead className="table-dark" >
            <tr style={{maxWidth:"350px"}}>
              {/* <th>#</th> */}
              <th></th>
              <th>Համար</th>
              <th>Ապր․ քանակ</th>
              {/* <th>Ամասաթիվ</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {docs?.map((doc, index) => (
              <React.Fragment key={doc.DocID}>
                <tr>
                  {/* <td>{index + 1}</td> */}
                  <td>
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={() => toggleDocDetails(doc.DocID)}
                    >
                      {expandedDoc === doc.DocID ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                  </td>
                  <td>{doc.DocID}</td>
                  <td>{doc.ProductCount}</td>
                   {/* <td>{doc.Dtn}</td> */}
                  <td>
                  <button
                    className="btn btn-sm btn-outline-success"
                    onClick={() => createReceptionDoc(doc)}
                  >
                    <FaCheckCircle />
                  </button>
                </td>
                </tr>
                {expandedDoc === doc.DocID && (
                  <tr>
                    <td colSpan={5}>
                      <div className="">
                        {/* <h5 className="mb-3">Հղված Ապրանքներ</h5> */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover text-center">
                            <thead className="table-secondary">
                              <tr>
                                <th>Կոդ</th>
                                <th>Նկար</th>
                                <th>Անուն</th>
                                <th>Քան</th>
                                <th>Առ&nbsp;գին</th>
                                <th>Վաճ&nbsp;գին</th>
                              </tr>
                            </thead>
                            <tbody>
                              {doc?.products?.map((prod: ReceptionDocProduct) => (
                                <tr key={prod.ProductID}>
                                  <td>{prod.ProductID}</td>
                                  <td>
                                    <img
                                      src={prod.ImageURL}
                                      width={50}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => openPhone(prod)}
                                    />
                                  </td>
                                  <td>{prod.ProductName}</td>
                                  <td>{prod.ProductCount}</td>
                                  <td>{prod.ProductCost}</td>
                                  <td>{prod.SalePrice}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
          :
          <></>
        }
      </div>
    </div>
  );
};

export default Reception;
