export class Helpers {
    static roundToTwoDecimal(value: number): number {
        return Math.round(value * 100) / 100; 
    }

    static formatNumberText(value: number | string): string {
        let num = typeof value === "number" ? value : parseFloat(value);

        if (isNaN(num)) {
          throw new Error("Invalid number");
        }
      
        // Եթե տասնորդական մասը զրոներ է, վերադարձնում ենք առանց տասնորդականների
        if (num % 1 === 0) {
            return num
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        // Հակառակ դեպքում կլորացնում ենք մինչև 2 նիշ և ֆորմատավորում ենք
        return num
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      }
}
