import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ProductCU } from "../../../Types/ProductType"; // Define your Product type

export const UpdateProductModal = React.memo(({ showModal, hideModal, saveModal, productTypes, productToEdit }: any) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm<ProductCU>();

  // If there is a product to edit, reset the form with its data when the modal is opened
  useEffect(() => {
    if (productToEdit) {
      reset(productToEdit);
    }
  }, [productToEdit, reset]);

  const save = (data: ProductCU) => {
    saveModal(data); // Pass the data to the save function
    reset(); // Reset the form after submitting
  };

  return (
    <Modal show={showModal} onHide={hideModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Թարմացնել Ապրանք</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(save)}>
          {/* Product Name */}
          <Form.Group className="mb-3" controlId="formProductName">
            <Form.Label>Ապրանքի Անուն</Form.Label>
            <Form.Control
              type="text"
              {...register("Name", {
                required: "Ապրանքի անունը լրացված չէ!!",
                minLength: { value: 3, message: "Ապրանքի անունը պետք է լինի գոնե 3 տառ!!" }
              })}
            />
            {errors.Name && (
              <Form.Text className="text-muted text-danger">
                {errors.Name?.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Product Price */}
          <Form.Group className="mb-3" controlId="formProductPrice">
            <Form.Label>Գին</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              {...register("SealPriceAMD", {
                required: "Գինը լրացված չէ!!",
                min: { value: 1, message: "Գինը պետք է լինի ավելի քան 0!!" }
              })}
            />
            {errors.SealPriceAMD && (
              <Form.Text className="text-muted text-danger">
                {errors.SealPriceAMD?.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Discounted Price */}
          <Form.Group className="mb-3" controlId="formProductDiscountedPrice">
            <Form.Label>Զեղչված Գին</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              {...register("DiscountedPriceAMD", {
                required: "Զեղչված գինը լրացված չէ!!",
                min: { value: 1, message: "Զեղչված գինը պետք է լինի ավելի քան 0!!" }
              })}
            />
            {errors.DiscountedPriceAMD && (
              <Form.Text className="text-muted text-danger">
                {errors.DiscountedPriceAMD?.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Product Type Selection */}
          <Form.Group className="mb-3" controlId="formProductType">
            <Form.Label>Ապրանքի Տեսակ</Form.Label>
            <Form.Control
              as="select"
              {...register("ProductType", { required: "Ապրանքի տեսակ ընտրեք!!" })}
            >
              <option value=""></option>
              {productTypes.map((type: any) => (
                <option key={type.ID} value={type.ID}>
                  {type.Name}
                </option>
              ))}
            </Form.Control>
            {errors.ProductType && (
              <Form.Text className="text-muted text-danger">
                {errors.ProductType?.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Product Description */}
          <Form.Group className="mb-3" controlId="formProductDescription">
            <Form.Label>Նկարագրություն</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("Description", { required: "Նկարագրությունը լրացված չէ!!" })}
            />
            {errors.Description && (
              <Form.Text className="text-muted text-danger">
                {errors.Description?.message}
              </Form.Text>
            )}
          </Form.Group>

          {/* Image URL */}
          <Form.Group className="mb-3" controlId="formProductImage">
            <Form.Label>Ապրանքի Լուսանկարի URL</Form.Label>
            <Form.Control
              type="text"
              {...register("ImageURL", { required: "Լուսանկարի URL լրացված չէ!!" })}
            />
            {errors.ImageURL && (
              <Form.Text className="text-muted text-danger">
                {errors.ImageURL?.message}
              </Form.Text>
            )}
          </Form.Group>

          <Modal.Footer style={{ paddingBottom: "0px" }}>
            <Button variant="default" onClick={() => hideModal(false)}>
              Չեղարկել
            </Button>
            <Button variant="primary" type="submit">
              Թարմացնել
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
});
